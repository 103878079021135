$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add safari class to body for safari specific css
    // *
    // *
    if (navigator.userAgent.indexOf('Safari') != -1 &&
        navigator.userAgent.indexOf('Chrome') == -1) {
        $("body").addClass("safari");
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();

    });

    fadeInElements();

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * draw icons
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function drawIcons() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".js-icon-list-item:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                $(this).addClass("active");
            } else {
                $(this).removeClass("active");
            }
        });
    }

    $(window).scroll(function () {
        drawIcons();

    });

    drawIcons();

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-nav-button").click(function () {
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active");
        // $("body, html").addClass("freeze");
    });

    $(".js-close-nav").click(function () {
        $(this).parents(".js-nav").toggleClass("active");
        $(".js-nav-button").removeClass("active");
        // $("body, html").removeClass("freeze");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hero video
    // *
    // *
    $(".js-hero-video-mask").click(function () {
        var $hero = $(this).parents('.js-hero'),
            $video = $(this).parents(".js-hero-video-wrapper").find(".js-hero-video");

        $hero.addClass("active");
        $video.get(0).play();
    });

    $(".js-hero-video").on("ended", function () {
        var $hero = $(this).parents('.js-hero');

        $hero.removeClass("active");
        $hero.find(".js-hero-headline").addClass("slowAnim");
        $hero.find(".js-hero-play").addClass("slowAnim");

    });

    $(".js-hero-video").click(function () {
        $(this).get(0).paused ? $(this).get(0).play() : $(this).get(0).pause();
    });


    const el = document.querySelector('.js-hero-video')
    if (el !== null) {
        const observer = new window.IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                // console.log('ENTER')
                return
            }
            // console.log('LEAVE')
            document.querySelector('.js-hero-video') !== null ? document.querySelector('.js-hero-video').pause() : undefined;
        }, {
            root: null,
            threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        })

        observer.observe(el);
    }






    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * swiper
    // *
    // *
    const swiper = new Swiper('.swiper', {
        // Default parameters
        slidesPerView: 2.5,
        spaceBetween: 55,
        autoplay: {
            delay: 3000,
        },
        loop: true,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            1200: {
                slidesPerView: 2.5,
                spaceBetween: 55,
            },
            0: {
                slidesPerView: 1.5,
                spaceBetween: 25,
            }
            // when window width is >= 480px
        }
    })
    const swiperText = new Swiper('.swiperText', {
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 50,
        pagination: {
            el: '.swiper-text-pagination',
            type: 'bullets',
        },
        autoplay: {
            delay: 3000,
        },
        loop: true,


    })
    const swiperHero = new Swiper('.swiperHero', {
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 50,
        autoplay: {
            delay: 2000,
        },
        loop: true,
        // Responsive breakpoints


    })


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});


